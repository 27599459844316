<template>
  <v-container class="mkt-content-container mt-10">
    <px-marketplace-view-header title="Detalhes da compra" :breadcrumbs="breadcrumbs">
      <template v-slot:before>
        <v-btn class="mr-1 mb-2" icon color="primary"
               @click="onClickBack">
          <v-icon>
            mdi-arrow-left
          </v-icon>
        </v-btn>
      </template>
    </px-marketplace-view-header>
    <div v-if="loading">
      <px-circular-loading/>
    </div>
    <div v-if="!loading && compra">
      <px-compra-pedido-header :compra="compra"
                               :is-from-carrinho="isFromCarrinho"/>
      <px-compra-dados-basicos :compra="compra" @change="getCompra"/>
      <px-compras-produtos class="mt-4" :compra="compra"/>
      <px-compra-mensagem class="mt-4" :compra="compra"/>
    </div>
  </v-container>
</template>

<script>
import CompraPedidoHeader from './CompraPedidoHeader.vue';
import CompraDadosBasicos from './CompraDadosBasicos.vue';
import ComprasProdutos from './ComprasProdutos.vue';
import CompraMensagem from './CompraMensagem.vue';
import CompraService from '../compras-service';

export default {
  data() {
    return {
      origin: this.$route.query.origin,
      loading: false,
      compra: null,
      id: this.$route.params.id,
      breadcrumbs: [
        { text: 'Minhas compras' },
        { text: 'Compra' },
      ],
    };
  },
  computed: {
    isFromCarrinho() {
      return this.origin === 'carrinho';
    },
  },
  methods: {
    onClickBack() {
      this.$router.push({
        name: 'main.compras',
      });
    },
    getCompra() {
      this.loading = true;
      CompraService.getCompra(this.id)
        .then(({ data }) => {
          this.compra = data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.getCompra();
  },
  components: {
    pxCompraPedidoHeader: CompraPedidoHeader,
    pxCompraDadosBasicos: CompraDadosBasicos,
    pxComprasProdutos: ComprasProdutos,
    pxCompraMensagem: CompraMensagem,
  },
};
</script>
