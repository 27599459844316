<template>
  <div v-if="compra.eventos">
    <v-row dense class="mt-5">
      <v-col cols="12">
        <px-collapse-btn class="body-1" v-model="showDetails"/>
      </v-col>
    </v-row>
    <v-expand-transition>
      <v-timeline v-if="showDetails" class="timeline-size">
        <v-timeline-item small right v-for="evento in events" :key="evento.id"
                         class="body-1">
          <div slot="opposite">
            <span>{{ evento.createdIn | dateDescription }}</span>
            <div v-if="isMobile">
              {{ label(evento) }}
              <div class="large-text">
                {{ evento.mensagem }}
              </div>
            </div>
          </div>
          <template v-if="!isMobile">
            {{ label(evento) }}
            <div class="large-text">
              {{ evento.mensagem }}
            </div>
          </template>
        </v-timeline-item>
      </v-timeline>
    </v-expand-transition>
  </div>
</template>

<script>
import { SituacaoCompraMarketplace } from 'px-business-components';

export default {
  props: {
    compra: Object,
  },
  data() {
    return {
      showDetails: true,
      situacaoCompra: SituacaoCompraMarketplace,
    };
  },
  computed: {
    events() {
      return this.compra.eventos.filter(v => v.situacao
          !== SituacaoCompraMarketplace.keys.SOLICITADO_REVISAO.key);
    },
    label() {
      return item => SituacaoCompraMarketplace.keys[item.situacao].description;
    },
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
};
</script>

<style scoped>
.timeline-size {
  width: 550px;
}
</style>
