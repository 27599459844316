<template>
  <v-card class="rounded-card card-shadow">
    <v-card-text class="text--primary pb-8 px-6">
      <template v-for="(pacote, index) in compra.pacotes">
        <div :key="index" class="mt-8">
          <px-compra-frete-marketplace class="body-1 font-weight-bold"
                                       :frete="pacote.frete"/>
          <px-compra-pacote-frete class="mt-3" :pacote="pacote"/>
          <v-list-item v-for="oferta in pacote.ofertas" :key="oferta.id" class="mt-3">
            <v-list-item-avatar class="square-avatar" height="60" width="60">
              <px-oferta-foto max-height="60"
                              max-width="60"
                              class="d-flex align-center justify-center"
                              :foto="oferta.oferta.foto"/>
            </v-list-item-avatar>
            <template v-if="!isMobile">
              <v-list-item-content class="list-item-oferta-title">
                <span class="body-1">{{ oferta.tituloProduto }}</span>
                <div>
                  <px-show-variacoes-completo :variacoes="oferta.variacoes" :is-small="false"/>
                </div>
                <div>
                  <px-especificacao-oferta :especificacao="oferta.especificacaoProduto"/>
                </div>
              </v-list-item-content>
              <v-list-item-content>
            <span class="body-2">
              <px-compras-produtos-preco :oferta="oferta"/>
            </span>
              </v-list-item-content>
              <v-list-item-content v-if="hasAnyIpi(pacote)">
                <px-compras-produtos-ipi :oferta="oferta"/>
              </v-list-item-content>
              <v-list-item-content v-if="hasValorAdicional(pacote)">
                {{ oferta.valorAdicional | currency }}
                <div class="caption">
                  valor adicional
                </div>
              </v-list-item-content>
              <v-list-item-content>
                <div class="font-weight-bold body-2">
                  {{ oferta.total | currency }}
                  <div class="caption">
                    total
                  </div>
                </div>
              </v-list-item-content>
              <v-list-item-action>
                <px-oferta-dialog :id-oferta="oferta.oferta.id"
                                  :comprador="compra.company"
                                  :quantidade="oferta.quantidade"
                                  btn-description="Recomprar"/>
              </v-list-item-action>
              <v-list-item-action>
                <px-open-oferta :oferta="oferta.oferta"/>
              </v-list-item-action>
            </template>
            <template v-else>
              <v-list-item-content>
                <div>
                  <span class="body-1">{{ oferta.tituloProduto }}</span>
                  <div>
                    <px-show-variacoes-completo :variacoes="oferta.variacoes" :is-small="false"/>
                  </div>
                  <div>
                <span class="body-1">
                  <px-compras-produtos-preco :oferta="oferta"/>
                </span>
                  </div>
                  <div v-if="hasAnyIpi(pacote)">
                    <px-compras-produtos-ipi :oferta="oferta" small/>
                  </div>
                  <div>
                    <span class="body-1">Total:</span>
                    <span class="font-weight-bold body-1">
                  {{ oferta.total | currency }}
                </span>
                  </div>
                  <div>
                    <px-open-oferta :oferta="oferta.oferta"/>
                  </div>
                </div>
              </v-list-item-content>
            </template>
          </v-list-item>
        </div>
      </template>
    </v-card-text>
  </v-card>
</template>

<script>
import ComprasProdutosPreco from './ComprasProdutosPreco.vue';
import CompraPacoteFrete from './CompraPacoteFrete.vue';

export default {
  props: {
    compra: Object,
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
    hasAnyIpi() {
      return pacote => pacote.ofertas.some(oferta => oferta.ipi && oferta.ipi.destacar);
    },
    hasValorAdicional() {
      return pacote => pacote.ofertas.some(oferta => !!oferta.valorAdicional);
    },
  },
  components: {
    pxComprasProdutosPreco: ComprasProdutosPreco,
    pxCompraPacoteFrete: CompraPacoteFrete,
  },
};
</script>

<style scoped>
.list-item-oferta-title {
  min-width: 30%;
}
</style>
