import axios from '../marketplace/axios-marketplace';

const getCompras = params => axios.get('/compras', {
  params,
});

const getComprasList = params => axios.get('/compras/list', {
  params,
});

const getProdutosCompra = id => axios.get(`/compras/${id}/ofertas/todas`);

const getCompra = id => axios.get(`/compras/${id}`);

const getAvaliacaoCompra = id => axios.get(`/compras/${id}/avaliacao`);

const cancelar = (id, data) => axios.put(`/compras/${id}/cancelar`, data);

const notificarMensagem = id => axios.post(`/notificacoes/compras/${id}`, {});

const getCobrancas = id => axios.get(`/compras/${id}/cobrancas`);

const getStatusFrete = idPacote => axios.get(`/pacotes/${idPacote}/fretes/status`);

export default {
  getCompras,
  getComprasList,
  getProdutosCompra,
  getCompra,
  cancelar,
  notificarMensagem,
  getAvaliacaoCompra,
  getCobrancas,
  getStatusFrete,
};
