<template>
  <v-card class="card-shadow rounded-card">
    <v-card-text class="text--primary pt-8 pb-8 px-6">
      <v-row dense>
        <v-col cols="12" sm="5">
          <span class="body-2 font-weight-bold">Fornecedor</span>
          <px-compra-dados-basicos-empresa class="body-1" :empresa="compra.fornecedor">
            <template v-slot:empresa>
              <px-popover-fornecedor small :fornecedor="compra.fornecedor"/>
            </template>
          </px-compra-dados-basicos-empresa>
        </v-col>
        <v-col cols="12" sm="4">
          <span class="body-2 font-weight-bold">Comprador</span>
          <px-compra-dados-basicos-empresa class="body-1" :empresa="compra.company"/>
        </v-col>
        <v-col cols="12" sm="3">
          <span class="body-2 font-weight-bold">Resumo do pedido</span>
          <v-row dense no-gutters class="body-1">
            <v-col cols="6">
              Produtos:
            </v-col>
            <v-col cols="6">
              {{ compra.totalProdutos | currency }}
            </v-col>
            <v-col cols="6">
              Frete:
            </v-col>
            <v-col cols="6">
              {{ compra.valorFrete | currency }}
            </v-col>
            <template v-if="compra.valorAdicional">
              <v-col cols="6">
                Adicional:
              </v-col>
              <v-col cols="6">
                {{ compra.valorAdicional | currency }}
              </v-col>
            </template>
            <template v-if="compra.desconto">
              <v-col cols="6">
                Desconto:
              </v-col>
              <v-col cols="6">
                -{{ compra.desconto | currency }}
              </v-col>
            </template>
            <v-col cols="6" class="font-weight-bold">
              Total:
            </v-col>
            <v-col cols="6">
              <span class="text--primary--color font-weight-bold">
                {{ compra.total | currency }}
              </span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row dense class="mt-5">
        <v-col cols="12" sm="4" lg="5">
          <span class="body-2 font-weight-bold">Forma de pagamento</span>
          <div>
            <px-forma-pagamento-marketplace
              class="body-1"
              show-icon
              :modo-pagamento="compra.modoPagamento"
              :forma-pagamento="compra.formaPagamento"
              :prazo-pagamento="compra.prazoPagamento"/>
            <div class="text--primary--color" v-if="compra.modoPagamento">
              <span v-if="isPagamentoNaoGerar">
                O fornecedor é responsável por providenciar o pagamento para sua empresa.
              </span>
              <span v-else>
                <px-situacao-pagamento small
                                       :situacao="compra.situacaoPagamento"/>
              </span>
            </div>
          </div>
        </v-col>
        <v-col cols="12" sm="4" lg="5" v-if="compra.finalidadeCompra">
          <span class="body-2 font-weight-bold">Finalidade da compra</span>
          <div class="body-1">
            <px-finalidade-compra-value :finalidade-compra="compra.finalidadeCompra"/>
          </div>
        </v-col>
      </v-row>
      <v-row class="mt-3">
        <v-col cols="12" sm="4" lg="6" v-if="compra.observacao">
          <span class="body-2 font-weight-bold">Observações internas</span>
          <div class="body-1">
            {{ compra.observacao }}
          </div>
        </v-col>
        <v-col cols="12" sm="4" lg="6" v-if="compra.arquivos.length">
          <span class="body-2 font-weight-bold">Anexos</span>
          <div>
            <px-anexo-chip :files="compra.arquivos"
                           download-base-path="files/download-comprador"/>
          </div>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12" sm="6">
          <px-compra-dados-basicos-timeline :compra="compra"/>
        </v-col>
        <v-col cols="12" sm="6" class="d-flex align-end flex-column justify-end">
          <px-avaliacao-compra-btn :compra="compra"/>
          <px-compra-cancelar-dialog
            btn-class="mt-3 normal-btn-text rounded-tag-small elevation-0"
            v-if="!hideCancelado"
            :compra="compra"
            :small="false"
            @movimentar="onMovimentar"/>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { SituacaoCompraMarketplace, SituacaoPagamento } from 'px-business-components';
import CompraDadosBasicosEmpresa from './CompraDadosBasicosEmpresa.vue';
import CompraDadosBasicosTimeline from './CompraDadosBasicosTimeline.vue';
import CompraCancelarDialog from './CompraCancelarDialog.vue';
import AvaliacaoCompraBtn from './AvaliacaoCompraBtn.vue';

export default {
  props: {
    compra: Object,
  },
  computed: {
    hideCancelado() {
      return this.compra.situacao === SituacaoCompraMarketplace.keys.CANCELADA.key
        || this.compra.situacao === SituacaoCompraMarketplace.keys.RECUSADA.key;
    },
    isPagamentoNaoGerar() {
      return this.compra.situacaoPagamento === SituacaoPagamento.keys.NAO_GERAR.key;
    },
  },
  methods: {
    onMovimentar() {
      this.$emit('change');
    },
  },
  components: {
    pxCompraDadosBasicosEmpresa: CompraDadosBasicosEmpresa,
    pxCompraDadosBasicosTimeline: CompraDadosBasicosTimeline,
    pxCompraCancelarDialog: CompraCancelarDialog,
    pxAvaliacaoCompraBtn: AvaliacaoCompraBtn,
  },
};
</script>
