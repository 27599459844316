<template>
    <div class="body-2">
      <span>
        {{ oferta.quantidade }} {{ oferta.unidadeMedida.descricao }} x
      </span>
      {{ oferta.precoUnitario | currency }}
    </div>
</template>

<script>
export default {
  props: {
    oferta: {
      type: Object,
      required: true,
    },
  },
};
</script>
