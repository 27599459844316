<template>
  <div v-if="empresa">
    <div>
      {{ empresa.pessoa.nome }}
      <slot name="empresa"></slot>
    </div>
    <div>
      {{ empresa.pessoa.cnpj | cnpj }}
    </div>
    <div>
      {{ empresa.endereco | endereco }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    empresa: Object,
  },
};
</script>
